import {Dispatch, SetStateAction} from 'react';
import {
    useMutation,
    DocumentNode,
    useQuery,
} from '@apollo/client';
import {useForm, FieldValues} from 'react-hook-form';
import {navigate} from 'gatsby';
import {VideoAttribute, videoAttributes} from '../../interfaces/videoAttributes';
import {
    Button,
    Modal,
    Form,
    Input,
} from '../../components/core';
import Switches from '../../components/partials/switches/switches';
import MultiSelectBox from '../../components/core/form/input/multiSelectBox';
import toastify from '../../helpers/toast/toastify';
import * as styles from './addCategory.module.css';

import {
    GetUploadPolicyParametersDocument,
    TeamLevel,
    AddThemeAttributeDocument,
    AddCategoryAttributeDocument,
    AddTypeAttributeDocument,
    AddUploadPolicyAttributeDocument,
    AddPlaylistTypeDocument,
} from '../../graphql-types';

interface IAddCategoryModal {
    setModal: Dispatch<SetStateAction<VideoAttribute | undefined>>,
    category: VideoAttribute,
}

type MutationConfig = {
    [key in VideoAttribute]: DocumentNode
};

const queryConfig: MutationConfig = {
    theme: AddThemeAttributeDocument,
    category: AddCategoryAttributeDocument,
    type: AddTypeAttributeDocument,
    uploadPolicy: AddUploadPolicyAttributeDocument,
    playlistType: AddPlaylistTypeDocument,
};

const AddCategoryModal = ({
    setModal,
    category,
}: IAddCategoryModal) => {
    const [addCategory] = useMutation(queryConfig[category]);
    const {
        data: attributesData,
        error: queryError,
        loading: isLoading,
    } = useQuery(GetUploadPolicyParametersDocument, {variables: {level: TeamLevel.Bu}});
    const {
        control,
        handleSubmit,
        watch,
        formState: {isValid},
    } = useForm<FieldValues>({
        mode: 'onChange',
    });

    const watchBUs = watch('whitelistedBUs');
    const onSubmit = (formData: FieldValues) => {
        const sendData = formData;

        if (category !== 'uploadPolicy') {
            delete sendData.whitelistedBUs;
            delete sendData.whitelistedRegions;
        }

        addCategory({
            variables: {
                input: sendData,
            },
            onCompleted(data) {
                if (data) {
                    navigate('/categories', {
                        state: {
                            toast: {
                                type: 'success',
                                text: 'Category item created',
                                config: {
                                    toastId: 'success1',
                                },
                            },
                        },
                    });
                    setModal(undefined);

                    return;
                }

                toastify({type: 'error', text: 'There was an error on creation'});
            },
            onError(err) {
                toastify({type: 'error', text: `There was an error on creation: ${err.message}`});
            },
            refetchQueries: ['getAttributes'],
            onQueryUpdated(observableQuery) {
                return observableQuery.refetch();
            },
        });
    };

    return (
        <Modal
            title="Create a category item"
            closeModal={() => setModal(undefined)}
            buttons={(
                <Button
                    text="Confirm and create"
                    disabled={category !== 'uploadPolicy'
                        ? !isValid
                        : !(isValid && (Array.isArray(watchBUs) && watchBUs.length > 0))}
                    size="medium"
                    onClick={handleSubmit(onSubmit)}
                />
            )}
        >
            <div className={styles.wrapper}>
                <div className={styles.info}>
                    <span>Category: <strong>{videoAttributes[category].title}</strong></span>
                </div>
                <div className={styles.formInput}>
                    <Form>
                        <Form.Row>
                            <Form.Column>
                                <Input
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Name is required',
                                        },
                                    }}
                                    title="Name"
                                    name="name"
                                    placeholder="name"
                                />
                            </Form.Column>
                            <Form.Column>
                                {category === 'uploadPolicy' && (
                                    <>
                                        {attributesData && attributesData.regions && (
                                            <MultiSelectBox
                                                optionsValues={attributesData.regions.map(region => ({
                                                    id: region?.id,
                                                    value: region?.id,
                                                    label: region?.name,
                                                }))}
                                                title="Whitelisted regions (Youtube)"
                                                placeholder="Choose the region"
                                                controllerName="whitelistedRegions"
                                                control={control}
                                                canType={true}
                                                required={true}
                                            />
                                        )}
                                    </>
                                )}
                            </Form.Column>
                        </Form.Row>
                        {category === 'uploadPolicy' && (
                            <>
                                <Form.Row>
                                    <Form.Column>
                                        {isLoading && ('Loading...')}
                                        {queryError && !attributesData && (`Error! ${queryError.message}`)}
                                        {attributesData && attributesData.teamsList && (
                                            <Switches
                                                data={attributesData.teamsList}
                                                group="whitelistedBUs"
                                                control={control}
                                                title="Whitelisted BUs (IX)"
                                            />
                                        )}
                                    </Form.Column>
                                </Form.Row>
                            </>
                        )}
                    </Form>
                </div>
            </div>
        </Modal>
    );
};

export default AddCategoryModal;
